.about-me .about-me-image {
    border-radius: var(--bs-border-radius);
    max-width: 100%;
}

.about-me .d-sm-block .about-me-image {
    margin-bottom: 10px;
}

.about-me .row {
    display: flex;
    align-items: center;
}

/* .about-me .description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about-me p {
    margin-bottom: 0;
} */