.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F8F9FA;
}
  
.page-main {
    flex: 1;
}

.footer-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page .container {
    max-width: 95%;
}

@media (max-width: 700px) {
    .page .container {
        max-width: 98%;
    }
}