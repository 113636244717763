@media (min-width: 700px) {
    .me-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .navbar-brand:hover {
        animation: app-logo-spin 0.75s linear 1, app-logo-spin-delayed 1.5s linear infinite 1.5s;
    }
}
  
@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes app-logo-spin-delayed {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.resume-modal .modal-title {
    margin-left: auto;
    margin-right: auto;
}

.resume-modal .modal-header .btn-close {
    margin: 0;
}

.resume-modal .modal-body,
.resume-modal .modal-body .container {
    padding: 0;
}

.resume-modal .react-pdf__Page__canvas {
    height: 100% !important;
    width: 100% !important;
}

.navbar-separator {
    color: var(--bs-navbar-color);
    font-size: 24px;
}