.achievements a.card {
    transition: 0.75s;
}

.achievements a.card:hover {
    border: 3px solid rgb(33, 37, 41);
}

.achievements .card-subtitle {
    font-style: italic;
    margin-bottom: 6px;
}

.achievements .row {
    margin-top: calc(var(--bs-gutter-x) * .5) !important;
}

@media (max-width: 700px) {

    .achievements .row {
        margin-bottom: calc(var(--bs-gutter-x) * -.5) !important;
    }

    .achievements .btn {
        margin-top: calc(var(--bs-gutter-x) * .5);
    }
    
}

.achievements .achievements-card {
    text-decoration: none;
    height: 100%;
}

.partial-achievements .row-2 {
    display: none !important;
}