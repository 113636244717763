@media (max-width: 700px) {
    .footer-col:nth-of-type(1) {
        margin-bottom: 10px;
    }

    .footer-col:nth-of-type(2) {
        margin-bottom: -5px;
    }
}

@media (min-width: 700px) {
    .footer-col:nth-of-type(1) {
        justify-content: left;
    }

    .footer-col:nth-of-type(2) {
        justify-content: right;
    }
}