label {
    font-weight: bold;
}

h2 {
    text-align: center;
}

textarea.form-control {
    min-height: 25vh;
}