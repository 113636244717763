.main-carousel .card-title {
    padding: 6px;
    margin-bottom: 0px;
}

.main-carousel .carousel {
    border-radius: var(--bs-border-radius);
    padding: 2px;
}

.main-carousel .carousel-item .carousel-image {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}

.main-carousel .carousel-caption {
    text-shadow: 0 1px 0 black;
}

.main-carousel .carousel-caption .carousel-subtext {
    font-size: 18px;
}

@media (max-width: 700px) {
    .main-carousel {
        .carousel-image {
            height: calc(200px + 13vw);
            object-fit: cover;
        }

        .carousel-subtext {
            margin-bottom: 0;
        }
    }
}